@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: 'DM Sans', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
	}
}

@layer components {
	.app-name {
		@apply font-bold uppercase text-brand-600 dark:text-brand-200;
	}
	
	.menu-icon, .menu-label{
		@apply font-medium text-gray-800 dark:text-white;
	}
	
	.menu-icon.active, .menu-label.active{
		@apply font-bold text-theme-500 dark:text-theme-300;
	}
	
	.card-icon{
		@apply h-6 w-6 text-theme-500 dark:text-theme-400;
	}
	
	.card-title {
		@apply text-xl font-bold text-theme-600 dark:text-theme-300;	
	}
	
	.table-header{
		@apply border-b border-gray-200 pb-[10px] text-start dark:!border-theme-600;
	}

	.panel-main-wrapper{
		@apply mx-[12px] h-full flex-none transition-all md:pr-2 max-w-[800px];
	}
	
}

input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg); 
	color: white;
	opacity: 0; 
	height: 16px; 
	width: 16px;
	position: absolute; 
	left: 50%;
	transform: translate(-50%, 0px);
} 

input:checked.defaultCheckbox::before { 
	opacity: 1;
}